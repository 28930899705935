import React, {useEffect, useRef, useState} from "react";


const AudioTests = (props) => {
    const [audio1Volume, setAudio1Volume] = useState(1)

    const [audio2Volume, setAudio2Volume] = useState(5)

    const audio1Ref = useRef()
    const audio2Ref = useRef()

    useEffect(() => {
        props.audio1.load()
        props.audio1.loop = true
        props.audio1.volume = audio1Volume / 10
        audio1Ref.current.volume = audio1Volume / 10

        props.audio2.load()
        props.audio2.loop = true
        props.audio2.volume = audio2Volume / 10
        audio2Ref.current.volume = audio2Volume / 10
    }, [props.audio1, props.audio2])

    useEffect(() => {
        audio1Ref.current.volume = audio1Volume / 10
        props.audio1.volume = audio1Volume / 10
    }, [audio1Volume])

    useEffect(() => {
        audio2Ref.current.volume = audio2Volume / 10
        props.audio2.volume = audio2Volume / 10
    }, [audio2Volume])

    return (
        <div style={{
            display: "flex",
            position: "absolute",
            flexDirection: "column",
            borderColor: "black",
            borderWidth: 1,
            padding: 10,
            borderRadius: 8,
            top: 320
        }}>
            <div>
                Регулировка громкости [1, 10]
            </div>
            <div style={{display: "flex", flex: 1, flexDirection: "row", justifyContent: "space-around", marginTop: 10}}>
                <audio
                    style={{display: "flex"}}
                    controls={false}
                    ref={audio1Ref}
                    loop={props.audio1.loop}
                    src={props.audio1.src}
                />
                <button
                    style={{
                        width: 50,
                        height: 40,
                        borderWidth: 1,
                        borderRadius: 4,
                        marginRight: 10,
                        borderColor: "black",
                        alignItems: "center"
                }}
                    onClick={() => {
                        audio1Ref.current.play()
                        setTimeout(() => {
                            audio1Ref.current.pause()
                            audio1Ref.current.load()
                        }, 1000);
                    }}
                >
                    Play
                </button>
                <input
                    type={"number"}
                    style={{
                        width: 80,
                        height: 40,
                        paddingLeft: 10,
                        borderWidth: 1,
                        borderColor: "black",
                        borderRadius: 4,
                        alignItems: "center",
                }}
                    value={audio1Volume}
                    onChange={(e) => {
                        if (e.target.value > -1 && e.target.value < 11) {
                            setAudio1Volume(e.target.value)
                        }
                    }}
                />
            </div>
            <div style={{display: "flex", flex: 1, flexDirection: "row", justifyContent: "space-around", marginTop: 10}}>
                <audio
                    style={{display: "flex"}}
                    controls={false}
                    ref={audio2Ref}
                    loop={props.audio2.loop}
                    src={props.audio2.src}
                />
                <button
                    style={{
                        width: 50,
                        height: 40,
                        borderWidth: 1,
                        borderRadius: 4,
                        marginRight: 10,
                        borderColor: "black",
                        alignItems: "center"
                    }}
                    onClick={() => {
                        audio2Ref.current.play()
                        setTimeout(() => {
                            audio2Ref.current.pause()
                            audio2Ref.current.load()
                        }, 1000);
                    }}
                >
                    Play
                </button>
                <input
                    type={"number"}
                    style={{
                        width: 80,
                        height: 40,
                        paddingLeft: 10,
                        borderWidth: 1,
                        borderColor: "black",
                        borderRadius: 4,
                        alignItems: "center",
                    }}
                    value={audio2Volume}
                    onChange={(e) => {
                        if (e.target.value > -1 && e.target.value < 11) {
                            setAudio2Volume(e.target.value)
                        }
                    }}
                />
            </div>
        </div>
    )
}

export default AudioTests