import React, { useState } from 'react';
import {Link, useFetcher, useNavigate} from "react-router-dom";
import {fakeAuthProvider} from "../../components/auth/auth";
import Header from "../../components/header";
import MainMenu from "../../components/MainMenu";

const AdminPage: React.FC = () => {
    return (
        <div style={{display: "flex", height: "100vh", flexDirection: "column"}}>
        <Header />
        <div style={{display: "flex", flex: 1, height: "100%", flexDirection: "row"}}>
            <MainMenu />
            <div style={{display: "flex", flex: 1, height: "100%"}}>
                <div style={{display: "flex", flex: 1, justifyContent: "center"}}>Краткая статистика</div>
            </div>
        </div>
        </div>
    );
};

export default AdminPage;
