import React, { useState } from 'react';
import {useNavigate} from "react-router-dom";
import {fakeAuthProvider} from "../../components/auth/auth";

const AuthPage: React.FC = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate()
    const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(e.target.value);
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };

    const handleUsername = async () => {
        // const data = await auth(username, password)
        try {
            await fakeAuthProvider.signin(username,password);
            console.log("LOG")
            if (fakeAuthProvider.user_type === 'ADMIN') {
                return navigate('/admin')
            }
            else {
                return navigate('/protected')
            }
        } catch (error) {
            return {
                error: "Invalid login attempt",
            };
        }

    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-50">
            <div className="bg-white p-8 rounded-lg shadow-md w-96">
                <h2 className="text-2xl font-semibold text-center mb-4">Caltat Toki</h2>
                <div className="mb-4">
                    <label htmlFor="username" className="block text-gray-600 mb-2">
                        Username
                    </label>
                    <input
                        type="text"
                        id="username"
                        className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                        placeholder="Enter your username"
                        value={username}
                        onChange={handleUsernameChange}
                    />
                </div>
                <div className="mb-6">
                    <label htmlFor="password" className="block text-gray-600 mb-2">
                        Password
                    </label>
                    <input
                        type="password"
                        id="password"
                        className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                        placeholder="Enter your password"
                        value={password}
                        onChange={handlePasswordChange}
                    />
                </div>
                <div className="text-center">
                    <button
                        className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
                        onClick={handleUsername}
                    >
                        Login
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AuthPage;
