import axios from "axios";
import {Simulate} from "react-dom/test-utils";
import compositionStart = Simulate.compositionStart;
import {apiConfig} from "../../config";

interface AuthProvider {
    isAuthenticated: boolean;
    username: null | string;
    user_type: null | string;
    signin(username: string, password: string): Promise<void>;
    signout(): Promise<void>;
}

/**
 * This represents some generic auth provider API, like Firebase.
 */
export const fakeAuthProvider: AuthProvider = {
    isAuthenticated: !!localStorage.getItem('token_caltatToki'),
    username: localStorage.getItem('caltatToki_me'),
    user_type: localStorage.getItem('user_type'),

    async signin(username: string, password: string) {
        const body = new FormData()
        body.append('username', username)
        body.append('password', password)
        const config = {
            method: 'post',
            url: `${apiConfig.url}/auth/auth`,
            data: body,
        };
        try {
            const response = await axios(config);
            console.log(response)
            // data = response.data;
            // return response.data;
            localStorage.setItem('token_caltatToki', response.data.access_token)
            localStorage.setItem('login', username)

            const configVerify = {
                method: 'get',
                url: `${apiConfig.url}/auth/verify`,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token_caltatToki')}`
                }
            };

            const responseVerify = await axios(configVerify);
            localStorage.setItem('user_type', responseVerify.data.type)
            localStorage.setItem('user_id', responseVerify.data.id)
            localStorage.setItem('user_in_project', response.data.in_project)
            localStorage.setItem('user_name', responseVerify.data.name)

            fakeAuthProvider.user_type = responseVerify.data.type
            fakeAuthProvider.isAuthenticated = true;
            fakeAuthProvider.username = username;
        } catch (error) {
            console.error('error', error);
            throw new Error("NOT AUTH")
        }
        // await new Promise((r) => setTimeout(r, 5000)); // fake delay

    },
    async signout() {
        await new Promise((r) => setTimeout(r, 500)); // fake delay
        localStorage.clear()
        fakeAuthProvider.isAuthenticated = false;
        fakeAuthProvider.username = "";
    },
};