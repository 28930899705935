import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {apiConfig} from "../../config";

export const CallHistory = () =>{
    const [response, setResponse] = useState(null)

    const getHistory = async ()=> {
        const config = {
            method: 'get',
            url: `${apiConfig.url}/calls/user`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token_caltatToki')}`
            }
        };
        try {
            const response = await axios(config);
            setResponse(response)
            console.log(response)

        } catch (error) {
            console.error('error', error);
        }
    }

    useEffect(() => {
        getHistory()
    }, []);

    let call_history = []
    if (response) {
        for (let i=0;i<response.data.length;i++){
            call_history.push(
                <div key={i} style={{fontSize: 15, width: 200, borderWidth: 1, display: "flex", flexFlow: "column", marginBottom: 10, paddingLeft: 10, borderColor: "black"}}>
                    <p>Кому:  {response.data[i].phone}</p>
                    <p>Во сколько:  {response.data[i].created_at.split("T")[1].split(".")[0]}</p>
                    <p>Продолжительность:  {response.data[i].duration}</p>
                </div>
            )
            if (i >= 9) {
                break
            }
        }
    }

    return (
        <div style={{position: "absolute", right: 20, display: "flex", flex: 0.2, flexFlow: "column", alignItems: "center"}}>
            <h3 style={{}}>История звонков</h3>
            <button style={{borderWidth: 1, padding: 5, borderColor: "black", marginBottom: 10}} onClick={() => {getHistory()}}>Обновить</button>
            {call_history}
        </div>
    )
}


