import React, {useEffect, useState} from 'react';
import {IncomeCall} from "../incomeCall";
import styles from "./index.module.css"

interface StatusCheckboxProps {
    label: string;
    value: string;
    onChange: (value: string) => void;
    selectedValue: string;
}

const StatusCheckbox: React.FC<StatusCheckboxProps> = ({
                                                           label,
                                                           value,
                                                           onChange,
                                                           selectedValue,
                                                       }) => {
    const handleStatusChange = () => {
        onChange(value);
    };


    return (
        <div
            className={styles.checkbox}
            style={{
                backgroundColor: selectedValue === value ? (
                    value === 'Готов' ? '#1BFF2A' :
                        value === 'Заполнение лида' ? '#FFB900FF' :
                            value === 'Обучение' ? '#ffb900' : '#FF4646'
                ) : 'rgb(246 246 251)'
        }}
            onClick={handleStatusChange}
        >
            <label>
                <input
                    type="checkbox"
                    style={{marginRight: 5, display: "none"}}
                    checked={selectedValue === value}
                    onChange={() => {}}
                />
                {label}
            </label>
        </div>
    );
};

function Statuses(props: any) {
    let selectedStatus = props.data.selectedStatus
    console.log(selectedStatus)
    const setSelectedStatus = props.data.setSelectedStatus

    const handleStatusChange = (value: string) => {
        if (value === 'Готов') {
            // navigator.mediaDevices.getUserMedia({
            //     audio: true
            // })
            setSelectedStatus(value)
        }
        else {
            setSelectedStatus(value);
        }
    };


    return (
        <div className={styles.main_container}>
            <StatusCheckbox
                label="Готов"
                value="Готов"
                onChange={handleStatusChange}
                selectedValue={selectedStatus}
            />
            <StatusCheckbox
                label="Отошел"
                value="Отошел"
                onChange={handleStatusChange}
                selectedValue={selectedStatus}
            />
            <StatusCheckbox
                label="Обучение"
                value="Обучение"
                onChange={handleStatusChange}
                selectedValue={selectedStatus}
            />
            <StatusCheckbox
                label="Заполнение лида"
                value="Заполнение лида"
                onChange={() => {}}
                selectedValue={selectedStatus}
            />
        </div>
    );
};


export default Statuses;
