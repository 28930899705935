import React, { useRef } from "react";

const AudioElements = React.forwardRef((props, parentRef) => {
    const { ringAudioSrc } = props;

    const audioRef = useRef(null);
    const soundRef = useRef(null);

    React.useImperativeHandle(parentRef, () => ({
        get audio() {
            return audioRef.current;
        },
        get sound() {
            return soundRef.current;
        },
        playSound: () => {
            if (soundRef.current) {
                soundRef.current.play();
            }
        },
        pauseSound: () => {
            if (soundRef.current) {
                soundRef.current.pause();
            }
        },
        muteCall: () => {
            if (audioRef.current) {
                audioRef.current.muted = true;
            }
        },
        unMuteCall: () => {
            if (audioRef.current) {
                audioRef.current.muted = false;
            }
        },
    }));

    return (
        <div>
            <audio
                {...{
                    ref: audioRef,
                    muted: false,
                    style: { visibility: "hidden" },
                }}></audio>
            {ringAudioSrc && (
                <audio
                    {...{
                        ref: soundRef,
                        src: ringAudioSrc,
                        autoPlay: false,
                        loop: true,
                        style: { visibility: "hidden" },
                    }}></audio>
            )}
        </div>
    );
});

AudioElements.defaultProps = {
    ringAudioSrc: null,
};

export default AudioElements;
