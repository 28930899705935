import React from "react";
import styles from "./index.module.css";
import {Link} from "react-router-dom";
// import Spinner from "../../../kit/Loaders/Spinner";

const Header = (props: any) => {
    const operator_page = props.operator_page
    return (
        <div style={{display: "flex", width: "100%", height: 60, backgroundColor: "white", boxShadow: "0px 0px 13px 3px rgba(34, 60, 80, 0.2)"}}>
            <Link style={{display: "flex", width: 300, justifyContent: "center", alignItems: "center"}} to={"/"}>
                <div style={{display: "flex", fontSize: 20}}>
                    uCall
                </div>
            </Link>
            <div style={{display: "flex", width: operator_page ? "calc(100vw - 600px + 15%)" : "calc(100vw - 300px)", alignItems: "center", justifyContent: "center", fontSize: 20}}>
                {props.title}
            </div>
        </div>
    )
};

export default Header;
