import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";
import Header from "../../components/header";
import axios from "axios";
import {ClipLoader} from "react-spinners";
import Select from "react-select";
import MainMenu from "../../components/MainMenu";
import {apiConfig} from "../../config";
import UserCard from "../../components/UserCard";

const Operators: React.FC = () => {
    const [responseUsers, setResponseUsers]: [any, any] = useState(null)
    const [createModal, setCreateModal]: [any, any] = useState(false)
    const [loading, setLoading]: [any, any] = useState(false)
    const [searchQuery, setSearchQuery] = useState('')
    const [user_lines, setUserLines]: [any, any] = useState([])
    const [responseProjects, setResponseProjects]: [any, any] = useState([])
    const cardsRefs = useRef<any>({})

    const clientRef: any = useRef(null);
    const [waitingToReconnect, setWaitingToReconnect]: [any, any] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [wsClient, setWsClient]: [any, any] = useState(null)

    const [isDisabledButton, setIsDisabled] = useState(false);
    const [disableTime, setDisableTime] = useState(10000);


    const getProjects = async ()=> {
        const config = {
            method: 'get',
            url: `${apiConfig.url}/project/list`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token_caltatToki')}`
            }
        };
        try {
            const response = await axios(config);

            setResponseProjects(response.data)
        } catch (error) {
            console.error('error', error);
        }
    }


    const getDisableTime = async ()=> {
        const config = {
            method: 'get',
            url: `${apiConfig.url}/project/disable-time`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token_caltatToki')}`
            }
        };
        try {
            const response = await axios(config);

            setDisableTime(response.data)
        } catch (error) {
            setDisableTime(10000)
        }
    }


    const  getUsers = async (without_loading: boolean, searchQ: string | null = null)=> {
        const config = {
            method: 'get',
            url: `${apiConfig.url}/auth/list?search_query=${searchQ ? searchQ : ''}`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token_caltatToki')}`
            }
        };
        try {
            if (!without_loading) {
                setLoading(true)
            }

            const response = await axios(config);
            setResponseUsers(response.data)

            let users_lines = []
            let users_li = []
            const cards_on_line = 4
            if (response.data) {
                for(let i=0; i<response.data.length; i++) {
                    users_li.push(
                        {
                            id: response.data[i].id,
                            user: response.data[i]
                        }
                    )
                    if (users_li.length >= cards_on_line) {
                        users_lines.push(
                            users_li
                        )
                        users_li = []
                    }
                }
                if (users_li) {
                    for (let i=users_li.length; i < cards_on_line; i++) {
                        users_li.push(
                            {
                                id: 0
                            }
                        )
                    }
                    users_lines.push(
                        users_li
                    )
                }
            }
            setUserLines(users_lines)
            setLoading(false)

            console.log("GET USERS REQUEST")

            return users_lines
        } catch (error) {
            console.error('error', error);
        }
    }

    // useEffect(() => {
    //     getProjects()
    //
    //     let users_lines = []
    //     let users_li = []
    //     const cards_on_line = 4
    //     if (responseUsers) {
    //         for(let i=0; i<responseUsers.length; i++) {
    //             users_li.push(
    //                 {
    //                     id: responseUsers[i].id,
    //                     user: responseUsers[i]
    //                 }
    //             )
    //             if (users_li.length >= cards_on_line) {
    //                 users_lines.push(
    //                     users_li
    //                 )
    //                 users_li = []
    //             }
    //         }
    //         if (users_li) {
    //             for (let i=users_li.length; i < cards_on_line; i++) {
    //                 users_li.push(
    //                     {
    //                         id: 0
    //                     }
    //                 )
    //             }
    //             users_lines.push(
    //                 users_li
    //             )
    //         }
    //     }
    //     setUserLines(users_lines)
    // }, [responseUsers])

    useEffect(() => {
        if (waitingToReconnect) {
            return;
        }

        if (!clientRef.current) {
            const WS_URL = apiConfig.wsUrl;
            const client = new WebSocket(WS_URL);
            clientRef.current = client;

            client.onerror = (e) => console.error(e);

            client.onopen = () => {
                setIsOpen(true);
                console.log('ws opened');
                client.send(`admin`)
                setWsClient(client)
            };

            client.onclose = () => {
                if (clientRef.current) {
                    console.log('ws closed by server');
                } else {
                    console.log('ws closed by app component unmount');
                    return;
                }

                if (waitingToReconnect) {
                    return;
                };

                setIsOpen(false);
                console.log('ws closed');

                setWaitingToReconnect(true);

                setTimeout(() => setWaitingToReconnect(null), 2000);
            };

            client.onmessage = message => {
                let user_id = message.data.split(':')[1]
                // getUsers(true, searchQuery).then((us_lines: any) => {
                //     for (let i = 0; i < us_lines.length; i++) {
                //         for (let y = 0; y < us_lines[i].length; y++) {
                //             console.log(us_lines[i][y].id, Number(user_id))
                //             if (us_lines[i][y].id == Number(user_id)) {
                //                 console.log("RESET TIMER")
                //                 cardsRefs.current[`${i}-${y}`].resetTimer()
                //                 return
                //             }
                //         }
                //     }
                // })
            };

            return () => {

                console.log('Cleanup');
                // Dereference, so it will set up next time
                clientRef.current = null;

                client.close();
            }
        }

    }, [waitingToReconnect]);

    useEffect(() => {
        console.log("TEST")
        getProjects()
        getDisableTime()
        getUsers(false, searchQuery)
    }, [])

    const [time, setTime] = useState(2000);
    const [isRunning, setIsRunning] = useState(true);

    useEffect(() => {
        console.log("SEARCH", searchQuery)
        setTime(50)
    }, [searchQuery]);

    useEffect(() => {
        let intervalId: any;
        if (time <= 0) {
            getUsers(false, searchQuery)
        }
        else {
            intervalId = setInterval(() => setTime(time - 50), 500);
        }
        return () => clearInterval(intervalId);
    }, [time]);

    console.log("RNDER")

    const handleClick = () => {
        // Отключаем кнопку и меняем цвет на серый
        setIsDisabled(true);

        // Вызываем вашу функцию
        getUsers(false, searchQuery);

        // Через 5 секунд возвращаем кнопку в исходное состояние
        setTimeout(() => {
            setIsDisabled(false);
        }, disableTime);
    };

    return (
        <div style={{display: "flex", flexDirection: "column", height: "100vh"}}>
            <Header title={"Операторы"}/>
            <div style={{display: "flex", flex: 1, flexDirection: "row"}}>
                <MainMenu />
                {createModal && <CreateOperatorComponent setCreateModal={setCreateModal} getUsers={getUsers}/>}
                {!createModal &&
                <div style={{display: "flex", height: 'calc(100vh - 60px)', flex: 1, flexDirection: "column", paddingLeft: 40}}>
                    <div style={{
                        display: "flex",
                        height: 60,
                        marginTop: 20,
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingRight: 40
                    }}>
                        <div style={{display: "flex"}}>
                            <div style={{display: "flex"}}>
                                <div style={{marginRight: 10}}>
                                    Поиск:
                                </div>
                                <input
                                    style={{borderWidth: 1, borderColor: "black", display: "flex", width: 400}}
                                    name={'searchQuery'}
                                    value={searchQuery}
                                    onChange={e => {
                                        setSearchQuery(e.target.value)
                                    }}
                                />
                            </div>
                            <div style={{marginLeft: 30}}>
                                <button
                                    style={{
                                        backgroundColor: isDisabledButton ? "gray" : "#7b05aa",
                                        padding: 10,
                                        color: "white",
                                        borderRadius: 5,
                                        cursor: isDisabledButton ? "not-allowed" : "pointer",
                                    }}
                                    onClick={handleClick}
                                    disabled={isDisabledButton}
                                >
                                    Обновить
                                </button>
                            </div>
                        </div>
                        <button onClick={() => {
                            setCreateModal(true)
                        }} style={{
                            display: "flex",
                            backgroundColor: "#1BFF2A",
                            color: "white",
                            width: 40,
                            height: 40,
                            justifyContent: "center",
                            borderRadius: 22.5,
                            fontSize: 25,
                        }}>
                            +
                        </button>
                    </div>
                    <div style={{display: "flex", maxHeight: '100%', height: "100%", overflow: 'auto', marginTop: 20}}>
                        <div style={{display: "flex", flex: 1, flexDirection: 'column'}}>
                            {user_lines.map((line: any, index_line: number) =>
                                <div style={{display: "flex", flexDirection: "row", marginBottom: 40, marginRight: 40}}>
                                    {line.map((user: any, index_column: number) => {
                                        if (user.id) {
                                            return (
                                                <UserCard
                                                    ref={ref => cardsRefs.current[`${index_line}-${index_column}`] = ref}
                                                    user={user.user}
                                                    searchQuery={searchQuery}
                                                    getUsers={getUsers}
                                                    projects={responseProjects}
                                                />
                                            )
                                        }
                                        else {
                                            return (
                                                <div
                                                    style={{
                                                        opacity: 0,
                                                        marginRight: "2%",
                                                        padding: 15,
                                                        display: "flex",
                                                        flex: 1,
                                                }}>

                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                }
            </div>
            {loading &&
                <div style={{
                    fontSize: 30,
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(100, 100, 100, 0.2)",
                    color: "white",
                    display: "flex",
                    paddingTop: "15%",
                    justifyContent: "center",
                }}>
                    <ClipLoader size={150} color={"black"}/>
                </div>
            }
        </div>
    );
};

export default Operators;


const CreateOperatorComponent = (props: any) => {
    const [name, setName] = useState('')
    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')
    const [isAdmin, setIsAdmin] = useState(false)
    const [createLoading, setCreateLoading] = useState(false)

    const navigate = useNavigate()

    const createOperator = async (name: any)=> {
        const config = {
            method: 'post',
            url: `${apiConfig.url}/auth/register`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token_caltatToki')}`
            },
            data: {
                name: name,
                login: login,
                password: password,
                is_admin: isAdmin
            }
        };
        try {
            console.log("T", {
                name: name,
                login: login,
                password: password,
                is_admin: isAdmin
            })
            const response = await axios(config);
            console.log("REG", response)
            props.setCreateModal(false)
            setCreateLoading(false)
            props.getUsers(false)
        } catch (error) {
            console.error('error', error);
        }
    }

    return (
        <div style={{display: "flex", flex: 1, justifyContent: "space-between", flexDirection: "column", borderRightWidth: 1, paddingLeft: 40, paddingRight: 40, paddingBottom: 20, paddingTop: 20}}>
            <div style={{display: "flex", height: 60, width: "100%", justifyContent: "flex-end"}}>
                <button onClick={() => {props.setCreateModal(false)}} style={{display: "flex", height: 60, width: 60, justifyContent: "center", borderWidth: 1, alignItems: "center"}}>
                    X
                </button>
            </div>
            <div style={{display: "flex", flex: 1, justifyContent: "center", flexDirection: "column", alignItems: "center"}}>
                <div>
                    <div style={{marginRight: 20, marginBottom: 20}}>
                        <div>Имя:</div><input style={{borderWidth: 1}} name={'name'} value={name} onChange={e => setName(e.target.value)} />
                    </div>
                    <div style={{marginRight: 20, marginBottom: 20}}>
                        <div>Логин:</div><input style={{borderWidth: 1}} name={'login'} value={login} onChange={e => setLogin(e.target.value)} />
                    </div>
                    <div style={{marginRight: 20, marginBottom: 20}}>
                        <div>Пароль:</div><input style={{borderWidth: 1}} name={'password'} value={password} onChange={e => setPassword(e.target.value)} />
                    </div>
                    <div style={{marginRight: 20, marginBottom: 20}}>
                        <div>Админ:</div><input type={"checkbox"} style={{borderWidth: 1}} name={'isAdmin'} onChange={e => setIsAdmin(!isAdmin)} />
                    </div>
                </div>
                {/*<div style={{marginRight: 20}}>*/}
                {/*    <div>Активность проекта:</div>*/}
                {/*    <Switch onChange={() => {setIsActive(!is_active)}} checked={is_active}/>*/}
                {/*</div>*/}
            </div>
            <div style={{display: "flex", height: 50, justifyContent: "center"}}>
                <button
                    onClick={() => {
                        if (!createLoading) {
                            createOperator(name);
                            setCreateLoading(true)
                        }
                    }}
                    style={{
                        display: "flex",
                        width: 200,
                        height: 50,
                        borderWidth: 1,
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "white"
                    }}>
                    {!createLoading ? "Создать оператора" : "Создание..."}
                </button>
            </div>
        </div>
    )
}
