import React, {useState} from "react";
import styles from "./index.module.css";
import Switch from "react-switch";
import {apiConfig} from "../../config";
import axios from "axios";
// import Spinner from "../../../kit/Loaders/Spinner";


const ProjectButton = (props: any) => {
    const [is_active, setIsActive] = useState(props.project.is_active)

    return (
        <button key={props.project.id} style={listStyle} onClick={() => {props.onClickItem(props.project.id)}}>
            <div style={{justifyContent: "flex-start", display: "flex", flexDirection: "column", flex: 1}}>
                <div style={{display: "flex", fontSize: 19}}>
                    {props.project.name}
                </div>
                <div style={{display: "flex", color: "rgb(73,73,73)", fontSize: 16}}>
                    Приоритет: {props.project.priority}
                </div>
            </div>
            <div style={{paddingTop: 6}}>
                <Switch
                    onChange={() => {
                        setIsActive(!is_active);
                        props.switchProjectActivity(props.project.id)
                    }}
                    checked={is_active}
                />
            </div>
        </button>
    )
}

const StandardMenu = (props: any) => {

    const switchProjectActivity = async (project_id: any)=> {
        const config = {
            method: 'patch',
            url: `${apiConfig.url}/project/active?project_id=${project_id}`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token_caltatToki')}`
            }
        };
        try {
            const response = await axios(config);
        } catch (error) {
            console.error('error', error);
        }
    }

    let items = []
    if (props.valuesList) {
        for (let i = 0; i < props.valuesList.length; i++) {
            items.push(
                <ProjectButton
                    project={props.valuesList[i]}
                    switchProjectActivity={switchProjectActivity}
                    onClickItem={props.onClickItem}
                />
            )
        }
    }

    return (
        <div style={{display: "flex", width: 300, height: 'calc(100vh - 110px)', flexDirection: "column", borderWidth: 1, borderColor: "black", justifyContent: "space-between"}}>
            <div style={{display: "flex", flex: 1, overflow: "auto", flexDirection: "column"}}>
                {items}
            </div>
            <div style={{display: "flex", height: 50, borderTopWidth: 1, borderColor: "black"}}>
                <button onClick={() => {props.onClickCreate(true)}} style={{display: "flex", flex: 1, alignItems: "center", justifyContent: "center"}}>
                    Добавить проект
                </button>
            </div>
        </div>
    )
};

export default StandardMenu;


const listStyle = {
    display: "flex",
    alignItems: "center",
    flexFlow: "row",
    justifyContent: "space-between",
    paddingRight: 10,
    paddingLeft: 10,
    paddingBottom: 10,
    paddingTop: 10,
    borderBottomWidth: 1,
    width: "100%"
}
