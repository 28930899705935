import React from 'react';
import {Link, useFetcher} from "react-router-dom";

const MainMenu: React.FC = () => {
    let fetcher = useFetcher();
    let isLoggingOut = fetcher.formData != null;

    return (
        <div style={{boxShadow: "0px 15px 13px 3px rgba(34, 60, 80, 0.2)", display: "flex", width: 300, height: "100%", flexDirection: "column", justifyContent: "space-between", backgroundColor: "white"}}>
            <div style={{display: "flex", height: 400, flexDirection: "column"}}>
                <div style={{display: "flex", height: 100, alignItems: "center", justifyContent: "center"}}>
                    {/*<div style={{width: 60, height: 60, display: "flex", borderWidth: 1, borderColor: "grey", borderRadius: 30, marginLeft: 40}}></div>*/}
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <p style={{marginBottom: 5}}>Логин: {localStorage.getItem("login")}</p>
                        <p>Имя: {localStorage.getItem("user_name")}</p>
                    </div>
                </div>
                <div style={{display: "flex", flex: 1}}>
                    <ul style={{display: "flex", flex: 1, flexDirection: "column"}}>
                        <Link style={liStyle} onClick={() => {window.location.href = "/admin/projects"}} to={"/admin/projects"}><li>Проекты</li></Link>
                        <Link style={liStyle} onClick={() => {window.location.href = "/admin/operators"}} to={"/admin/operators"}><li>Операторы</li></Link>
                        {/*<li style={liStyle}><Link to={"/pjsips"}>Поставщики</Link></li>*/}
                        <Link style={liStyle} onClick={() => {window.location.href = "/admin/statistic"}} to={"/admin/statistic"}><li>Статистика</li></Link>
                    </ul>
                </div>
            </div>
            <div>
                <Link style={{display: "flex", height: 50, justifyContent: "center", borderTopWidth: 1, alignItems: "center"}} to={"/protected"}>На страницу оператора</Link>
                <fetcher.Form method="post" action="/logout" style={{display: "flex", height: 50}}>
                    <button type="submit" disabled={isLoggingOut} style={{display: "flex", flex: 1, alignItems: "center", justifyContent: "center", borderTopWidth: 1}}>
                        Выйти
                    </button>
                </fetcher.Form>
            </div>
        </div>
    );
};

export default MainMenu;


const liStyle = {
    display: "flex",
    // backgroundColor: "rgb(100 105 244)",
    borderWidth: 1,
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
}
