import {
    Outlet,
    RouterProvider,
    createBrowserRouter,
    redirect, useNavigate
} from "react-router-dom";
import {fakeAuthProvider} from "./components/auth/auth";
import Auth from "./pages/auth";
import React, {useEffect, useState} from "react";
import AdminPage from "./pages/admin";
import Projects from "./pages/projects";
import Operators from "./pages/operator";
import StatisticPage from "./pages/statistic";
import ProtectedPage from "./pages/protectedPage";
import axios from "axios";
import {apiConfig} from "./config";


import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: "https://543aea51a1b24fe0b5b3c45a2cc6e773@sentry.caltat.com/12",
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["localhost", /^https:\/\/ucall\.center\/api/],
        }),
        new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


const router = createBrowserRouter([
    {
        id: "root",
        path: "/",
        Component: Layout,
        children: [
            {
                index: true,
                loader: checkAuth
            },
            {
                path: "/login",
                Component: Auth,
            },
            {
                path: "/protected",
                Component: ProtectedPage,
            },
            {
                path: "/admin",
                Component: AdminLayout,
                children: [
                    {
                        path: "/admin/",
                        Component: AdminPage,
                    },
                    {
                        path: "/admin/projects",
                        Component: Projects,
                    },
                    {
                        path: "/admin/operators",
                        Component: Operators,
                    },
                    // {
                    //     path: "/pjsips",
                    //     Component: Pjsips,
                    // },
                    {
                        path: "/admin/statistic",
                        Component: StatisticPage,
                    },
                ]
            },
        ],
    },
    {
        path: "/logout",
        async action() {
            // We signout in a "resource route" that we can hit from a fetcher.Form
            await fakeAuthProvider.signout();
            return redirect("/");
        },
    },
]);


export default function App() {
    return (
        <RouterProvider router={router} fallbackElement={<p>Initial Load...</p>} />
    );
}

function Layout() {
    const [init, setInit] = useState(false)
    const navigate = useNavigate()
    console.log("LAYOUT FAKEUTH", fakeAuthProvider.isAuthenticated)

    const Verify = async () => {
        const configVerify = {
            method: 'get',
            url: `${apiConfig.url}/auth/verify`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token_caltatToki')}`
            }
        };
        try {
            let response = await axios(configVerify);
            if (response) {
                console.log("VERIFY", response.data)
                localStorage.setItem('user_in_project', response.data.in_project)
            }
        }
        catch(error) {
            console.log("no auth")
        }
    }

    useEffect(() => {
        Verify()

        if (!fakeAuthProvider.isAuthenticated) {
            console.log("TO LOGIN")
            navigate("/login")
            setTimeout(() => {
                setInit(true)
            }, 50)
        }
        setTimeout(() => {
            setInit(true)
        }, 50)
    }, [])

    if (!init) {
        return null
    }

    return <Outlet />
}


function AdminLayout() {
    const [init, setInit] = useState(false)
    const navigate = useNavigate()
    console.log("ADMIN_CHECK", fakeAuthProvider.user_type)

    useEffect(() => {
        if (fakeAuthProvider.user_type === "OPERATOR") {
            console.log("TO operator ")
            navigate("/protected")
            setTimeout(() => {
                setInit(true)
            }, 50)
        }
        setTimeout(() => {
            setInit(true)
        }, 50)
    }, [])

    if (!init) {
        return null
    }

    return <Outlet />
}

async function checkAuth() {
    console.log("chackAuth FAKEUTH", fakeAuthProvider.isAuthenticated)
    if (!fakeAuthProvider.isAuthenticated) {
        return redirect("/login");
    }
    else {
        if (fakeAuthProvider.user_type === 'ADMIN') {
            return redirect("/admin");
        }
        else {
            return redirect("/protected");
        }
    }
}
