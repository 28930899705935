import {redirect, useFetcher, useNavigate} from "react-router-dom";
import React from "react";
import {fakeAuthProvider} from "../auth/auth";

const LogoutButton: React.FC = () => {
    const navigate = useNavigate()

    return (
        <div style={{display: "flex", flexFlow: "column"}}>
            <div style={{width: 100, height: 50, justifyContent: "center", borderWidth: 1, borderColor: "black", margin: 20, marginTop: 0, borderRadius: 5}}>
                <div style={{width: "100%", height: "100%"}}>
                    <button
                        onClick={async () => {
                            await fakeAuthProvider.signout();
                            navigate(0)
                            return navigate("/");
                        }}
                        style={{width: "100%", height: "100%", display: "block"}}
                    >
                        {"Выход"}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default LogoutButton